import React from "react"
import { Link } from 'gatsby'

import Layout from "../../components/layout"
import Spacer from "../../components/spacer"
import Tooltip from "../../components/tooltip"

const Page = () => (
  <Layout title="Services" keywords={[`rail api`, `train ticket`, `api`]}>
    <content>
      <h1>Services</h1>

      <p>Hazardous Frog offer a range of software development and consultancy services in addition to our 
      flagship <Link to="/smartix/" className="printLink">smartix</Link> application.</p>

      <p>We provide free support to our <Link to="/smartix/" className="printLink">smartix</Link> customers, but
      we can also provide more substantial consultancy services if required. We can even take on a commission 
      to build a rail retail site for you.</p>

      <p>hazradous Frog will continue to take on complex application commissons. If you have a project that requires
      the best possible software engineerign team, then please <Link to="/contact-us/" className="printLink">drop us a line</Link>.</p>

    </content>
  </Layout>
)

export default Page
